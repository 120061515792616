import PropTypes from 'prop-types';
import React from 'react';

export const HTMLContent = ({ content, className }: any) => (
    <div className={className} dangerouslySetInnerHTML={{ __html: content }} />
);

const Content = ({ content, className }: any) => <div className={className}>{content}</div>;

Content.propTypes = {
    content: PropTypes.node,
    className: PropTypes.string
};

HTMLContent.propTypes = Content.propTypes;

export default Content;
